var FileCache = {}
//urllist:需要获取的文件列表
//param 根据param获取设置好的文件列表 eg.report
FileCache.require = function(urllist, param) {
    if ($.isArray(urllist)) {
        var length = urllist.length;
        for (var i = 0; i < length; ++i) {
            var url = urllist[i] + "?_version=" + FileCache.version || "";
           
            if(urllist[i].includes(".min.js")||urllist[i].includes(".min.css")){
                url = urllist[i];
            }
            var url_path = url;
            if (url.indexOf("?") > -1) {
                url_path = url.substring(0, url.indexOf("?"));
            }

            if (FileCache.getExt(url_path) == ".js") {
                if (!document.querySelector(`script[src="${ url }"]`)) document.write("<script src='" + url + "' type='text\/javascript'><\/script>");
            }
            if (FileCache.getExt(url_path) == ".css") {
                if (!document.querySelector(`link[href="${ url }"]`)) document.write("<link href='" + url + "' type='text\/css' rel='stylesheet'\/>");
            }
        }
    }

    var param_urllist = FileCache[param];
    if ($.isArray(param_urllist)) {
        var param_length = param_urllist.length;

        for (var k = 0; k < param_length; ++k) {
            var urlk = param_urllist[k] + "?_version=" + FileCache.version;
            
            if(param_urllist[k].includes(".min.js")||param_urllist[k].includes(".min.css")){
                urlk = param_urllist[k];
            }
            var url_pathk = urlk;
            if (urlk.indexOf("?") > -1) {
                url_pathk = urlk.substring(0, urlk.indexOf("?"));
            }

            if (FileCache.getExt(url_pathk) == ".js") {
                document.write("<script src='" + urlk + "' type='text\/javascript'><\/script>");
            }
            if (FileCache.getExt(url_pathk) == ".css") {
                document.write("<link href='" + urlk + "' type='text\/css' rel='stylesheet'\/>");
            }
        }
    }
};
FileCache.getExt = function(filename) {
    var last = filename.lastIndexOf(".");
    var result = filename.substring(last);
    return result;
};
FileCache.report = [
    "/static/js/vue.min.js",
    "/static/js/common.js",
    "/static/css/global.css",
    "/static/css/report.css",
    "/static/css/font-awesome.min.css",
    "/static/css/zTreeStyle.css",
    "/static/js/jquery.ztree.all-3.5.min.js",
    "/static/js/WdatePicker.js",
    "/static/js/report.js",
];


var datetime = new Date().getTime();
var headElement = document.getElementsByTagName("head")[0] || document.documentElement;
if(!top.Cache || !top.Cache.version){
    $.ajax({
        type: "get",
        async: false,
        url: "/static/js/version.js?_version=" + datetime,
        success: function(data) {
            var script = document.createElement("script");
            script.type = "text/javascript";
            script.innerHTML  = data;
            headElement.appendChild(script);
            FileCache.version = version;
            var Cache = top.Cache || {};
            Cache.version = version;
        }
    });
}else{
    FileCache.version = top.Cache.version;
}


